import axios from "axios";
import * as ActionType from "./history.type";

// get admin history

export const getAdminHistory = (id, start, limit, startDate, endDate) => (dispatch) => {
  axios
    .get(
      `bdSettlement/getBdSettlement?bdId=${id}&start=${start}&limit=${limit}&startDate=${startDate}&endDate=${endDate}`
    )
    .then((res) => {
      dispatch({
        type: ActionType.GET_ADMIN_HISTORY,
        payload: { history: res.data.data, total: res.data.total },
      });
    })
    .catch((error) => console.log("error", error));
};

export const getAdminCashOut = (id, start, limit, startDate, endDate, type) => (dispatch) => {
  axios
    .get(
      `bdRedeem/getBdWise?bdId=${id}&start=${start}&limit=${limit}&startDate=${startDate}&endDate=${endDate}&type=${type}`
    )
    .then((res) => {
      dispatch({
        type: ActionType.GET_ADMIN_CASHOUT,
        payload: { history: res.data.data, total: res.data.total },
      });
    })
    .catch((error) => console.log("error", error));
};

export const getBdEarning = (id, startDate,endDate) => (dispatch) => {
  axios
    .get(`weekHistory/bdEarning?bdId=${id}&startDate=${startDate}&endDate=${endDate}`)
    .then((res) => {
      dispatch({
        type: ActionType.GET_ADMIN_HISTORY,
        payload: { history: res?.data?.data, summary: res?.data?.summary },
      });
    })
    .catch((error) => console.log("error", error));
};

export const getAgencyHistory = (id, startDate, endDate) => (dispatch) => {
  axios
    .get(
      `agencySettlement/agencySettlementForAgency?agencyId=${id}&startDate=${startDate}&endDate=${endDate}`
    )
    .then((res) => {
      dispatch({
        type: ActionType.GET_AGENCY_HISTORY,
        payload: { history: res.data.history },
      });
    })
    .catch((error) => console.log("error", error));
};
