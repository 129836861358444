import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAdminHistory } from "../../store/history/history.action";
import InfiniteScroll from "react-infinite-scroll-component";

const Income = (props) => {
  const { history, total } = useSelector((state) => state.history);
  const bdId = localStorage.getItem("bdId");

  const startDate = props?.startDate;
  const endDate = props?.endDate;
  const [hasMore, setHasMore] = useState(true);

  const dispatch = useDispatch();
  const [activePage, setActivePage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loadingMore, setLoadingMore] = useState(false);


  useEffect(() => {
    dispatch(
      getAdminHistory(bdId, activePage, rowsPerPage, startDate, endDate)
    );
  }, [bdId, activePage, rowsPerPage, startDate, endDate]);

  const fetchData = () => {
    if (!loadingMore) {
      setLoadingMore(true);
      setTimeout(() => {
        if (history.length < total) {
          setActivePage(activePage + 1);
          setRowsPerPage(rowsPerPage + 10);
          setLoadingMore(false);
        } else {
          setHasMore(false);
        }
      }, 500); // Adjust delay as needed
    }
  };

  function formatNumber(value) {
    if (value >= 1000000000) {
      return (value / 1000000000).toFixed(1) + 'B'; // Format as billion
    } else if (value >= 1000000) {
      return (value / 1000000).toFixed(1) + 'M'; // Format as million
    } else if (value >= 10000) {
      return (value / 1000).toFixed(1) + 'K'; // Format as thousand
    } else {
      return value; // No formatting for smaller numbers
    }
  }


  return (
    <>
      {history?.length > 0 ? (
        <InfiniteScroll
          dataLength={history.length}
          next={fetchData}
          hasMore={hasMore}
          loader={
            total > 10 ? (
              <p className="text-dark text-center">Loading...</p>
            ) : null
          }
          endMessage={<p className="text-center mt-2">No more items</p>}
        >
          <div className="row">
            {history?.map((data) => {
              return (
                <>
                  <div className="col-12 mb-2 mt-1">
                    <div className="agency-invitation bg-white p-2">
                      <div className="row">
                        <div className="col-4">
                          <span
                            style={{
                              color: "#a7a7a7",
                              fontSize: "8px",
                            }}
                          >
                            Coin Income
                          </span>
                          <div className="d-flex align-items-center">
                            <img
                              src={require("../../assets/images/rcoin.png")}
                              style={{ height: "20px", width: "20px" }}
                              alt=""
                            />
                            <span
                              className="mb-0 ms-1 fw-bolder"
                              style={{
                                color: "#ff8300",
                                fontSize: "15px",
                              }}
                            >
                              {data?.coin ? formatNumber(data?.coin.toFixed(0)) : 0}
                            </span>
                          </div>
                        </div>

                        <div className="col-4">
                          <span
                            style={{
                              color: "#a7a7a7",
                              fontSize: "8px",
                            }}
                          >
                            Host Coin
                          </span>
                          <div className="">
                            <span
                              className="mb-0 ms-1 text-dark"
                              style={{
                                fontSize: "15px",
                              }}
                            >
                              {data?.hostCoin ? formatNumber(data?.hostCoin.toFixed(0)) : 0}
                            </span>
                          </div>
                        </div>
                        <div className="col-4">
                          <span
                            style={{
                              color: "#a7a7a7",
                              fontSize: "8px",
                            }}
                          >
                            Agency Coin
                          </span>
                          <div className="d-flex align-items-center">
                            <img
                              src={require("../../assets/images/rcoin.png")}
                              style={{ height: "20px", width: "20px" }}
                              alt=""
                            />
                            <span
                              className="mb-0 ms-1 fw-bolder"
                              style={{
                                color: "#ff8300",
                                fontSize: "15px",
                              }}
                            >
                              {data?.agencyCoin ? formatNumber(data?.agencyCoin.toFixed(0)) : 0}
                            </span>
                          </div>
                        </div>

                        <div className="col-4">
                          <span
                            style={{
                              color: "#a7a7a7",
                              fontSize: "8px",
                            }}
                          >
                            Total Amount
                          </span>
                          <div className="d-flex align-items-center">
                            <img
                              src={require("../../assets/images/rcoin.png")}
                              style={{ height: "20px", width: "20px" }}
                              alt=""
                            />
                            <span
                              className="mb-0 ms-1 fw-bolder"
                              style={{
                                color: "#ff8300",
                                fontSize: "15px",
                              }}
                            >
                              {data?.dollar ? data?.dollar.toFixed(0) : 0}
                            </span>
                          </div>
                        </div>

                        <div className="col-4">
                          <span
                            style={{
                              color: "#a7a7a7",
                              fontSize: "8px",
                            }}
                          >
                            Month
                          </span>
                          <div className="d-flex align-items-center">
                            <img
                              src={require("../../assets/images/rcoin.png")}
                              style={{ height: "20px", width: "20px" }}
                              alt=""
                            />
                            <span
                              className="mb-0 ms-1 fw-bolder"
                              style={{
                                color: "#ff8300",
                                fontSize: "15px",
                              }}
                            >
                              {data?.createdAt ? data?.createdAt.split("-")[1] : 0}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              );
            })}
          </div>
        </InfiniteScroll>
      ) : (
        <div className="d-flex justify-content-center align-items-center my-4">
          <span>No data found</span>
        </div>
      )}
    </>
  );
};

export default Income;
